import { Box, Stack, styled, TableRow, Typography } from "@mui/material";
import GenericModal from "components/GenericModal";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import MDButton from "components/MDButton";
import { useMutation } from "react-query";
import { updateYardStikStatusToProceed } from "api/yarnstick/updateYardStikStatusToProceed";
import { useSnackbar } from "notistack";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {},
}));

const YardStikAttachmentModal = ({
  applicantName,
  yardstikData,
  getFilePreview,
  open,
  setOpen,
}) => {
  // Mutation to update venue custom fields
  const { mutateAsync: updateYardStickStatus } = useMutation(updateYardStikStatusToProceed);
  const { enqueueSnackbar } = useSnackbar();

  const proceed = () => {
    const body = {
      candidate_id: yardstikData?.yardstickId,
      report_id: yardstikData?.report?.reportId,
    };
    console.log("Body", body);

    updateYardStickStatus(
      { body },
      {
        onSuccess: (data) => {
          enqueueSnackbar("Proceeded successfully", {
            variant: "success",
            autoHideDuration: 3000,
          });
          setOpen(false);
        },
        onError: (error) => {
          enqueueSnackbar("Error in proceeding", {
            variant: "error",
            autoHideDuration: 3000,
          });
          setOpen(false);
        },
      }
    );
  };

  const header = (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box sx={{ mt: 2, mb: 1, ml: 2 }}>
        <Typography variant="h5" color="dark">
          {`Background Check for ${applicantName} is ${yardstikData?.report?.status}`}
        </Typography>
      </Box>
      <IconButton onClick={() => setOpen(false)}>
        <CloseIcon />
      </IconButton>
    </Box>
  );
  const modalBody = (
    <Stack display="flex" direction="row" alignItems="center">
      {yardstikData?.report?.status === "consider" && (
        <Box display="flex" flex={1}>
          <MDButton type="button" color="info" size="large" onClick={() => proceed()}>
            Proceed this applicant
          </MDButton>
        </Box>
      )}
      <Box display="flex" flex={1} alignItems="center" justifyContent="center">
        {getFilePreview(450, true)}
      </Box>
    </Stack>
  );

  return (
    <>
      <GenericModal
        open={open}
        setOpen={setOpen}
        header={header}
        body={modalBody}
        // buttons={modalButtons}
        width="60%"
        overflow="auto"
      />
    </>
  );
};

export default YardStikAttachmentModal;
